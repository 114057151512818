import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { handleRedirectInternal, mapData } from '../../Product/common/components'
import AuthContext from '../../Product/context/auth/authContext'
import Spinner from '../../Component/Spinner'
import { useTranslation } from 'react-i18next'
import FacebookLogin from 'react-facebook-login'
// import GoogleLogin from 'react-google-login'
import cartContext from '../../Product/context/cart/cartContext'
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import { Button } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import AlertContext from '../../Product/context/alert/alertContext'
import { profile } from '../../Services'

function LoginFunction({ title, subTitle, after_login_redirect_path, autoComplete }) {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { getUserCart } = useContext(cartContext)
    const { login_email } = useParams()
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reset_password, setResetPassword] = useState(false)
    const { setAlert } = useContext(AlertContext)
    const { t } = useTranslation()
    let searchParams = new URLSearchParams(window.location.search)
    let { login, responseStatus, checkReturnUser } = authContext

    let [passwordShown, setPasswordShown] = useState(false)
    const [isPos, setIsPos] = useState(false)

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const isDesktop = useMediaQuery({
        query: '(min-width: 600px)',
    })

    const validationArray = Yup.object({
        username:
            global.storeConfigration?.login_using_id?.value == 1
                ? Yup.string()
                      .test('is-email-or-number', 'Must be a valid email or ID', function (value) {
                          const isValidEmail = Yup.string().email().isValidSync(value)
                          const isValidNumber = Yup.number().integer().positive().isValidSync(value)

                          return isValidEmail || isValidNumber
                      })
                      .required(t('required') + '!')
                : Yup.string()
                      .email(t('invalid_email'))
                      .required(t('required') + '!'),
        password: Yup.string().required(t('required') + '!'),
    })

    const resetPasswordValidationArray = Yup.object({
        username:
            global.storeConfigration?.login_using_id?.value == 1
                ? Yup.string()
                      .test('is-email-or-number', 'Must be a valid email or ID', function (value) {
                          const isValidEmail = Yup.string().email().isValidSync(value)
                          const isValidNumber = Yup.number().integer().positive().isValidSync(value)

                          return isValidEmail || isValidNumber
                      })
                      .required(t('required') + '!')
                : Yup.string()
                      .email(t('invalid_email'))
                      .required(t('required') + '!'),
        new_password: Yup.string()
            .min(8, 'Enter min 8 characters')
            .required('Enter valid new password'),
        confirm_new_password: Yup.string()
            .min(8, 'Enter min 8 characters')
            .required('Enter valid confirm password'),
    })

    const resetPasswordSubmit = async (value) => {
        console.log('resetPasswordSubmit', value)

        if (value.new_password !== value.confirm_new_password) {
            setAlert('Password mismatch', 'error')
        } else {
            if (value.username) {
                let body = {
                    username: value.username,
                    type: Yup.string().email().isValidSync(value.username)
                        ? 'email'
                        : 'register_number',
                    password: value.new_password,
                    return_user: true,
                }

                let changePassword = await profile.forgetPassword(body)
                if (changePassword.success === 'yes') {
                    setResetPassword(false)
                    setAlert('Password updated sucessfully', 'success')
                } else {
                    setAlert(changePassword.reason, 'error')
                }
            }
        }
    }

    useEffect(() => {
        //console.log('login_email', login_email)
        if (
            window.location.pathname.includes('/autologin') ||
            window.location.pathname.includes('/auto-login')
        ) {
            setLoad(true)
            let username = login_email
            let password = '12345678'
            let autologin = 1
            let encrypt = window.location.pathname.includes('/auto-login') ? 1 : 0
            setIsPos(window.location.pathname.includes('/auto-login-pos'))
            let loginValues = {
                username: username,
                password: password,
                autologin: autologin,
                encrypt: encrypt,
            }
            //console.log('loginvalues', loginValues)
            login(loginValues)
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            username: localStorage.username ? localStorage.username : '',
            password: localStorage.password ? localStorage.password : '',
            remember_me: localStorage.remember_me ? localStorage.remember_me : false,
            community: 'auction_io',
            is_auctionio: 1,
            account_id:
                global.storeConfigration?.enable_payment_gateway_register?.value == 1
                    ? process.env.REACT_APP_AUCTIONPAYID
                    : undefined,
            description:
                global.storeConfigration?.enable_payment_gateway_register?.value == 1
                    ? 'Login Customer Creation'
                    : undefined,
            register_to_payment_gateway:
                global.storeConfigration?.enable_payment_gateway_register?.value,
            login_with_suspend: global.storeConfigration?.suspended_user_access?.value
                ? global.storeConfigration.suspended_user_access.value
                : 0,
            is_bidderid_checker: global.storeConfigration?.login_using_id?.value == 1 ? 1 : 0,
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            setLoading(true)
            if (values.remember_me) {
                localStorage.username = values.username
                localStorage.password = values.password
                localStorage.remember_me = values.remember_me
            } else {
                delete localStorage.username
                delete localStorage.password
                delete localStorage.remember_me
            }
            login(values)
        },
    })
    const formik2 = useFormik({
        initialValues: {
            username: '',
            new_password: '',
            confirm_new_password: '',
            field: 'email',
            community: 'auction_io',
            is_auctionio: 1,
        },
        validationSchema: resetPasswordValidationArray,
        onSubmit: resetPasswordSubmit,
    })

    const responseFacebook = (response) => {
        const params = {
            username: response.email,
            password: response.id,
            community: 'auction_io',
            is_auctionio: 1,
        }
        login(params)
    }
    // console.log(
    //     jwt_decode(
    //         'eyJhbGciOiJSUzI1NiIsImtpZCI6IjBlNzJkYTFkZjUwMWNhNmY3NTZiZjEwM2ZkN2M3MjAyOTQ3NzI1MDYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiI1NjM0MzkwNDYzMzgta3MyNmNtbzdqNGtmNjU1YThscjAwNHMxY3NicHVldnAuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiI1NjM0MzkwNDYzMzgta3MyNmNtbzdqNGtmNjU1YThscjAwNHMxY3NicHVldnAuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDkxOTg1NzM5NDQ0NjU2NzE2NTUiLCJlbWFpbCI6InNhYW5tdWdha3VtYWFyLmF1Y3Rpb25zb2Z0d2FyZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzAwODI3Mzg4LCJuYW1lIjoiU2Fhbm11IFNrIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0pXdS1DYzZNQkRHV25adWNEWEpoTFJlUnFrV29OcnFLNVQwX3ZzWU9COT1zOTYtYyIsImdpdmVuX25hbWUiOiJTYWFubXUiLCJmYW1pbHlfbmFtZSI6IlNrIiwibG9jYWxlIjoiZW4tR0IiLCJpYXQiOjE3MDA4Mjc2ODgsImV4cCI6MTcwMDgzMTI4OCwianRpIjoiYWQxN2M1YTg3N2M4NDYyMWE2OTQzY2I4YjNlN2JlODZhZjcxZjk5NSJ9.WK2qbvDJRlzpdfuQwf9gReUFkzyaUYPTnmCYDl9MKtlDzWPG0fgjgV7XGKguhRIZNWBdI-XdqDKAnDn-ywTbeNdbfaup7R75Ec5VhH3vfotGGhgBuvGjPn05seYfOhwPCuehk1hXXYokt0IxkzFm4W68UkpxGsEsCQ7D3nXQJsEMAXJqLBQelHhrYj7qxHL3lBq1-DeYo5-hkL5rKLkMFqrdBvvHpC_KIcnIOdkx_NcSV93Nl0rutKFzRSmcL6RYogb1eMrIuMm4kPWETpmopkcFpbDjY6laouAiAqCY0wEQD17wHNfFamlGzoT4yfask98nTC5uV9NQKJGHs7VFXA',
    //     ),
    // )
    const responseGoogle = (response) => {
        console.log(response, 'checkResponse')
        const decode = jwt_decode(response.credential)
        const params = {
            username: decode.email,
            password: decode.sub,
            community: 'auction_io',
            is_auctionio: 1,
        }
        login(params)
    }
    const rememberMe = {
        formik: formik,
        data: [
            {
                label: t('rember_me'),
                name: 'remember_me',
                type: 'checkbox',
                placeholder: 'Remember me',
                class: 'w-100',
                formik: formik,
            },
        ],
    }

    const loginInfo = {
        formik: formik,
        data: [
            {
                label:
                    global.storeConfigration?.login_using_id?.value == 1
                        ? 'Email / Id'
                        : t('email'),
                name: 'username',
                type: global.storeConfigration?.login_using_id?.value == 1 ? 'text' : 'email',
                placeholder:
                    global.storeConfigration?.login_using_id?.value == 1
                        ? 'Enter Email / Id'
                        : t('enter_email'),
                class: 'col-12',
                autoFocus: true,
                autoComplete: autoComplete ? 1 : 0,
                onBlur: async (values) => {
                    if (values.target.value) {
                        if (
                            global.storeDetails?.site_id == 'AS0605' ||
                            global.storeDetails?.site_id == 'AS0732' ||
                            global.storeDetails?.site_id == 'AS0752' ||
                            global.storeDetails?.site_id == 'AS0619' ||
                            global.storeConfigration?.return_user?.value == 1
                        ) {
                            // setLoad(true)
                            const isValidEmail = Yup.string()
                                .email()
                                .isValidSync(values.target.value)
                            console.log('onBlur', isValidEmail, values.target.value)
                            let username = values.target.value
                            let loginValues = {
                                username: username,
                                type: isValidEmail ? 'email' : 'register_number',
                                return_user: true,
                                site_id: global.storeDetails?.site_id,
                            }
                            let is_return_user = await checkReturnUser(loginValues)
                            // setLoad(false)
                            formik2.values['username'] = username
                            setResetPassword(is_return_user)
                        }
                    }
                },
            },
            {
                label: t('password'),
                name: 'password',
                type: passwordShown ? 'text' : 'password',
                placeholder: t('enter_password'),
                class: 'col-12',
                autoComplete: autoComplete ? 1 : 0,
                endAdornment: passwordShown ? (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility_off
                    </span>
                ) : (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility
                    </span>
                ),
            },
        ],
    }

    const resetPassword = {
        formik: formik2,
        data: [
            {
                label: t('email') + ' / Id',
                name: 'username',
                type: 'text',
                placeholder: t('enter_email'),
                class: 'col-12',
                autoComplete: 0,
                disabled: true,
            },
            {
                label: 'New Password',
                name: 'new_password',
                type: passwordShown ? 'text' : 'password',
                placeholder: 'Enter a new password',
                class: 'col-12',
                autoFocus: true,
                autoComplete: 0,
                endAdornment: passwordShown ? (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility_off
                    </span>
                ) : (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility
                    </span>
                ),
            },
            {
                label: 'Confirm Password',
                name: 'confirm_new_password',
                type: passwordShown ? 'text' : 'password',
                placeholder: 'Enter a confirm password',
                class: 'col-12',
                autoComplete: 0,
                endAdornment: passwordShown ? (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility_off
                    </span>
                ) : (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility
                    </span>
                ),
            },
        ],
    }

    // const googleLogin = global?.pluginConfiguration?.login_with_google?.client_key
    //     ? useGoogleLogin({
    //           onSuccess: (credentialResponse) => responseGoogle(credentialResponse),
    //       })
    //     : null

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(
                        history,
                        after_login_redirect_path
                            ? after_login_redirect_path
                            : global.storeConfigration?.redirect_login?.value
                            ? global.storeConfigration.redirect_login.value
                            : searchParams.get('auction_id')
                            ? 'search/product-buyer-auction/' +
                              searchParams.get('auction_id') +
                              '?id_new=' +
                              searchParams.get('auction_id')
                            : isPos && global.pluginConfiguration?.pos?.enable
                            ? 'dashboard/pos'
                            : 'search',
                    )
                    getUserCart()
                    setLoad(false)
                }
                setLoading(false)
            }
        }
    }, [responseStatus])
    return (
        <>
            {load && <Spinner />}
            {!reset_password ? (
                <form className="login-ip-box" onSubmit={formik.handleSubmit}>
                    <h1>{title ? title : 'Sign In'}</h1>
                    {subTitle && <h4>{subTitle}</h4>}
                    <div className="row">{mapData(loginInfo)}</div>
                    <div className="d-flex justify-content-between align-items-center fpRm mb-2">
                        {mapData(rememberMe)}
                        <div className="w-100 text-right forgottext">
                            <Link to="/forgot_password">{t('forgot_password')}?</Link>
                        </div>
                    </div>
                    <div className="text-center form-group">
                        <PrimaryButton disabled={loading} label={t('login')} type="submit" />
                        {global.pluginConfiguration?.login_with_facebook?.enable == 1 ? (
                            <div className="mt-3">
                                <FacebookLogin
                                    appId={
                                        global.pluginConfiguration?.login_with_facebook?.client_key
                                    }
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    size="medium"
                                />
                            </div>
                        ) : null}
                        {global.pluginConfiguration?.login_with_google?.enable == 1 &&
                        global?.pluginConfiguration?.login_with_google?.client_key ? (
                            <div className="mt-3 googleLogin">
                                {/* <Button className="googleLoginBtn" onClick={() => googleLogin()}>
                                <img src="/assets/images/google.png" />
                                Sign in with Google
                            </Button> */}

                                <GoogleOAuthProvider
                                    clientId={
                                        global?.pluginConfiguration?.login_with_google
                                            ?.client_key || ''
                                    }
                                    width={isDesktop ? '450px' : '300px'}
                                >
                                    <GoogleLogin
                                        onSuccess={(credentialResponse) => {
                                            responseGoogle(credentialResponse)
                                        }}
                                        shape="rectangular"
                                        size="large"
                                        className="asdasd"
                                        width={isDesktop ? '450px' : '300px'}
                                        onError={() => {
                                            console.log('Signup Failed')
                                        }}
                                        text="signin_with"
                                    />
                                </GoogleOAuthProvider>
                            </div>
                        ) : null}
                        <div className="newAcc mt-3 text-center">
                            <p>
                                Don&apos;t have an account?{' '}
                                <Link to="/sign-up">Create Account</Link>
                            </p>
                        </div>
                    </div>
                </form>
            ) : (
                <form className="login-ip-box" onSubmit={formik2.handleSubmit}>
                    <h1>Reset Password</h1>
                    <div className="row">{mapData(resetPassword)}</div>

                    <div className="text-center form-group">
                        <PrimaryButton disabled={loading} label="Reset Password" type="submit" />
                        <div className="newAcc mt-3 text-center">
                            <p>
                                Don&apos;t have an account?{' '}
                                <Link to="/sign-up">Create Account</Link>
                            </p>
                        </div>
                    </div>
                </form>
            )}
        </>
    )
}

export default LoginFunction
